import React from 'react'
import { Box, Flex } from 'reflexbox/styled-components'
import { PageRow, PageCopy, PageWrap } from '../components/global'
import SEO from '../components/seo'

export default () => {
  return (
    <PageWrap>
      <SEO title="Contact Success" />
      <PageRow>
        <Flex flexDirection="column" px={[2, 3]}>
          <Box
            ml={[0, `${(2 / 10) * 100}%`]}
            mr={[0, `${(2 / 10) * 100}%`]}
            width={[1, `${(6 / 10) * 100}%`]}
          >
            <br />
            <PageCopy>
              <p>
                Thank you for your enquiry, we'll be in touch soon. For
                inspiration and updates on our work in progress you can follow
                us on{' '}
                <a
                  href="https://www.instagram.com/jhdixonbuilders"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram.
                </a>
              </p>
            </PageCopy>
          </Box>
        </Flex>
      </PageRow>
    </PageWrap>
  )
}
